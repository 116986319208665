* {
    box-sizing: border-box;
}

.eqjs-menu-itemDiv hr {
    margin:0;
}

.eqjs-qp-panel {
    background: #f9f9f9;
    height: 100%;
    position: relative;
    font: normal 14px Trebuchet MS, Tahoma, Verdana, Geneva, Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.eqjs-qp-panel *, .eqjs-qc-panel *, .eqjs-ep-panel *, .eqjs-cb-panel *, .eqjs-grb-panel * {
    outline: none !important;
}

.eqjs-qp-panel *:focus, .eqjs-qc-panel *:focus, .eqjs-ep-panel *:focus, .eqjs-cb-panel *:focus, .eqjs-grb-panel *:focus {
    border: 1px dotted #0099CC;
}

.eqjs-ep-panel input[type=checkbox]:focus {
    outline: 1px dotted #0099CC !important;
}

.eqjs-cb-row-column-entattr.active a:focus {
    border-color: white;    
}

.eqjs-addrow {
    padding: 10px 0 0 0;
}

.eqjs-qp-addrow, .eqjs-qc-addrow {
	flex-grow: 1;
}

.eqjs-addrow a {
    margin: 0 10px;
    display: inline-block;
}


.eqjs-addrow-empty {
    padding: 5px 0 0 0;
}

.eqjs-addrow a {
    font-weight: bold;
    font-size: 13px;
    color: #333333;
    text-decoration: none;
    border-bottom: 1px dashed;
}

.eqjs-addrow > a:hover{
    border-bottom: 1px solid;
}


.eqjs-qc-panel {
    background: #f9f9f9;
    height: 100%;
    position: relative;
    font: normal 14px Trebuchet MS, Tahoma, Verdana, Geneva, Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.eqjs-qc-header {
    font: normal 15px Calibri;
    border-bottom: 1px solid silver;
    padding: 0 0 2px 0;
    overflow: hidden;
    white-space: nowrap;
    flex: 0 0 auto;
}

.eqjs-qc-header div {
    font: bold 15px Calibri;
    color: #666;
    display: inline-block;
}

.eqjs-qc-header-expression {
    margin-left: 28px;
    min-width: 300px;
    width: auto;
}

.eqjs-qp-row {
    min-height: 28px;
    position:relative;
    display: flex;
}

.eqjs-qp-level-offset {
    display: inline-block;
    width: 20px;
}

.eqjs-qc-row{
    padding-left: 10px;
    min-height: 27px;
    cursor: pointer;
    position: relative;
}

.eqjs-qc-col-landing-slot, .eqjs-qp-cond-landing-slot {
    border-left: 2px solid #fb9c2f;
    border-right: 2px solid #fb9c2f;
    height: 18px;
    padding: 8px 0;
    margin: 0 2px;
}

.eqjs-qc-col-landing-slot > div, .eqjs-qp-cond-landing-slot > div {
    height: 2px;
    width: 100%;
    background-color: #fb9c2f;
}

.eqjs-qp-row:hover, .eqjs-qc-row:hover, .eqjs-cb-row:hover, .eqjs-sb-row:hover {
    background: #ece9e9;
}

.eqjs-qp-row.active, .eqjs-qc-row.active, .eqjs-cb-row.active, .eqjs-sb-row.active .eqjs-sortable-helper {
    background: #ece9e9;
}

.eqjs-sortable-helper {
    background-color: white;
    padding: 3px 20px;
    border: 2px solid #fb9c2f;
    border-radius: 4px;
}

.eqjs-qp-row-group {
    margin-left:0px;
}

.eqjs-qp-row-group-root {
    font-weight: bold;
    font-size: 13px;
    padding: 0 0 0 10px;
}

.eqjs-qp-row-group-root .eqjs-qp-grvalueelement a {
    font-size: 13px;
    font-weight: bold;
}

.eqjs-qp-group-root {
	overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.eqjs-qp-conditions-root {
	max-height: none;
}

.eqjs-qp-group-root > .eqjs-qp-conditions{
    overflow-y: auto;
    padding: 0 0 6px 0;
    position: relative;
}

.eqjs-qp-group {
    display: flex;
    flex-direction: column;
}

.eqjs-qp-row-condition.eqjs-qp-condition-injoin {
    margin-left: 1px;
    box-shadow: inset 3px 0px 0px 0px #3abb1b;
}

.eqjs-qp-row-condition.eqjs-qp-condition-parameterized {
    margin-left: 1px;
    box-shadow: inset 3px 0px 0px 0px #a74cf1;
}

.eqjs-qp-row-condition.eqjs-qp-condition-parameterized.eqjs-qp-condition-injoin {
    margin-left: 1px;
    box-shadow: inset 3px 0px 0px 0px #a74cf1, inset 4px 0px 0px 0px white, inset 7px 0px 0px 0px #3abb1b;
}

.eqjs-qp-condelement {
    margin: 5px 8px 0 0;
    padding: 1px;
    vertical-align: top;
    font-size: 14px;
    font-weight: normal;
	flex: 0 0 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

.eqjs-qp-condelement.eqjs-qp-attrelement-wide {
    flex-shrink: 1;
}

.eqjs-qp-condelement.eqjs-qp-valueelement {
    flex-shrink: 2;
}

.eqjs-qc-colelement {
    display : inline-block;
    margin: 3px 8px 0 0;
    vertical-align: top;
    font-size: 14px;
    font-weight: normal;
}

.eqjs-qc-hidden .eqjs-qc-colelement {
    opacity: 0.5;
}

.eqjs-qc-expr-block{
    display:inline-block;
    min-width:300px;
    margin-left: 28px;
}

.eqjs-qc-params-block, .eqjs-qc-params-block span {
    display:inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
}

.eqjs-qc-params-block {
    margin-right: 8px;
}

.eqjs-qc-params-block .eqjs-qp-valueelement {
    margin-right: 0;
}

.eqjs-qc-params-block .eqjs-qp-valueelement input[type="text"] {
    padding: 0;
}


.eqjs-qc-column-checkbox{
    width: 16px;
    height: 16px;
}

.eqjs-qc-column-checkbox.enabled{
    width: 16px;
    height: 16px;
}

.eqjs-qp-grelement {
    margin: 5px 8px 0 0;
    vertical-align: top;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
	flex: 0 0 auto;
	width: auto;
}

.eqjs-qp-condelement > a, a.eqjs-qp-condelement, .eqjs-qp-grelement > a, a.eqjs-qp-grelement {
    border-bottom: 1px dotted #AEAEAE;
    color: #0099CC;
    text-decoration: none;
    white-space: nowrap;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.eqjs-qc-colelement > a, a.eqjs-qc-colelement {
    border-bottom: 1px dotted #AEAEAE;
    color: #0099CC;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.eqjs-qp-condelement > a:hover, a.eqjs-qp-condelement:hover, .eqjs-qp-grelement > a:hover, a.eqjs-qp-grelement:hover, .eqjs-qc-colelement > a:hover, a.eqjs-qc-colelement:hover {
    border-bottom: 1px solid #aeaeae;
}

.eqjs-qp-valueelement {
	flex: 0 1 auto;
}

.eqjs-qp-valueelement a, a.eqjs-qp-valueelement {
    color: #074B7A;
}

.eqjs-qc-attrelement > a, a.eqjs-qc-attrelement {
    max-width: 285px;
}

.eqjs-qc-captionelement > a, a.eqjs-qc-captionelement {
    max-width: 285px;
}

.eqjs-qp-disabled .eqjs-qp-valueelement span, .eqjs-qp-readonly .eqjs-qp-valueelement span{
    max-width: 167px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.eqjs-qp-valueelement input[type="text"]{
    font-size: 15px;
    padding: 2px 5px;
    border: 1px solid silver;
    outline: none;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
}

.eqjs-qp-disabled span, .eqjs-qc-disabled span  {
    color: #9E9E9E;
    font-weight: normal;
    margin-bottom: 2px;
}

.eqjs-qp-readonly span {
    color: #333333;
}

.eqjs-qp-error {
    color: red;
    font-weight:bold;
}

.eqjs-qp-ve-loader {
    width:16px;
    height:11px;
}

/* levels */
.eqjs-qp-level-1 {
    padding-left: 10px;
}

.eqjs-qp-level-2 {
    padding-left: 20px;
}

.eqjs-qp-level-3 {
    padding-left: 30px;
}

.eqjs-qp-level-4 {
    padding-left: 40px;
}

.eqjs-qp-level-5 {
    padding-left: 50px;
}

.eqjs-qp-level-6 {
    padding-left: 60px;
}

.eqjs-qp-level-7 {
    padding-left: 70px;
}

.eqjs-qp-level-8 {
    padding-left: 80px;
}

.eqjs-qp-level-9 {
    padding-left: 90px;
}

.eqjs-qp-level-10 {
    padding-left: 100px;
}

.eqjs-qp-level-11 {
    padding-left: 110px;
}

.eqjs-qp-level-12 {
    padding-left: 120px;
}

.eqjs-qp-level-13 {
    padding-left: 130px;
}

.eqjs-qp-level-14 {
    padding-left: 140px;
}

.eqjs-qp-level-15 {
    padding-left: 150px;
}

/* levels end */

/* dialog */
.eqjs-dialog{
    overflow: visible !important;    
}

.eqjs-qp-ve-subquery{
    overflow: visible !important;
    overflow: visible !important;
}

.eqjs-qp-condition-buttonsBlock {
    width: auto;
    display: none;
	margin-left: auto;
}

.eqjs-qp-row[data-show-buttons] > .eqjs-qp-condition-buttonsBlock {
    display: flex;
}

.eqjs-column-buttonsBlock{
    max-width: 100px;
    position: absolute;
    right: 3px;
    top: 0;
    width: auto;
}

.eqjs-button-placeholder, .eqjs-qp-button-placeholder {
	margin: 3px 2px 0 2px;
    width: 22px;
    height: 22px;
}

.eqjs-gp-column-button {
    width: 23px;
    height: 23px;
}

.eqjs-gp-column-button-menu {
    float: right;
}

.eqjs-gp-col-landing-slot {
    width: 2px;
    background-color: #fb9c2f !important;
}

.eqjs-qc-sortbutton-placeholder {
    float: none;
    position: absolute;
}

.eqjs-button {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.eqjs-qp-condition-button, .eqjs-qc-column-button, .eqjs-qc-sortbutton {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.eqjs-qc-row  .eqjs-qc-buttonsBlock {
    display: none;
}

.eqjs-qc-row  .eqjs-column-sortbutton {
    display: none;
}

.eqjs-qc-row[data-show-buttons]  .eqjs-qc-buttonsBlock {
    display: flex;
}

.eqjs-qc-row[data-show-buttons]  .eqjs-column-sortbutton {
    display: block;
}

.eqjs-qp-condition-checkbox{
    width: 16px;
    height: 16px;
}

.eqjs-qp-condition-checkbox.enabled{
    width: 16px;
    height: 16px;
}

.eqjs-ep-panel {
    background: #f9f9f9;
    height: 100%;
    min-height: 200px;
    position: relative;
}

.eqjs-ep-entity{
    clear: both;    
}

.eqjs-ep-panel > div.eqjs-ep-entity{
    bottom: 41px;
    left: 0;
    overflow-x: auto;
    position: absolute;
    right: 0;
    top: 30px;
}

.eqjs-ep-filter-box{
    left: 0;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
}

.eqjs-ep-filter-box-input{
	width: 100%;
}

.eqjs-ep-entity-block{
    display: block;
    color: black;
    font: normal 12px Arial;
    margin: 0 10px;
}

.eqjs-ep-entity-node{
    border-bottom: 1px solid #E4E4E4;
    padding: 3px 0;
	white-space:nowrap;
}

.eqjs-ep-entity-node-label{
    display: inline-block;
    cursor: pointer;
    font: bold 15px Calibri;
    white-space:nowrap;
	margin: 0;
}

.eqjs-ep-entity-node-label input{
    margin: 0 5px 0 0;
	vertical-align: bottom;
	position: relative;
	top: -3px;
}

.eqjs-ep-entity-block input{
    margin: 0 5px;
}

.eqjs-ep-entity-offset{
    display: inline-block;
    width: 15px;
}

.eqjs-ep-entity-attr-label{
    color: #333;
    font-size: 14px;
    font-weight:normal;
    padding: 5px 0 0 5px;
    display: inline-block;
    cursor: pointer;
    white-space:nowrap;
	margin: 0;
}

.eqjs-ep-entity-attr-label.draggable {
    cursor: move;
}

/* root entity attribute class */
.eqjs-ep-entity-attr-root {
    position: relative;
}

.eqjs-ep-entity-attr-label input{
    margin: 0 5px 0 0;
	vertical-align: bottom;
	position: relative;
	top: -3px;
}

.eqjs-ep-entity-node-button{
    display:inline-block;
    padding: 4px 10px 7px;
}

.eqjs-ep-tool-panel{
    bottom: 0;
    left: 0;
    padding-top: 8px;
    position: absolute;
    right: 0;
    height: 41px;
}

.eqjs-ep-tool-panel div.eqjs-ep-tool-panel-left-side{
    float: left;
    width: 50%;
}

.eqjs-ep-tool-panel div.eqjs-ep-tool-panel-right-side{
    float: right;
    width: 50%;
}

.eqjs-ep-tool-panel div div{
    float: inherit;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0 10%;
}

.eqjs-highlight{
    height: 20px;
}

.eqjs-drophover {
    border: 1px dotted black;
}

.eqjs-ep-entity > .eqjs-ep-entity-children{
    float: left;
    width: 100%;
}

.eqjs-menu-close-btn {
    position:absolute;
    bottom: 0;
    cursor: pointer;
    text-align: center;
    font-size: 1.1em;
    width: 100%;
    height: 40px;
    border-top: 1px solid #666;
}

.eqjs-menu-rootLevel{
    background-color: #888;
}

.eqjs-menu-levelDiv{
    background: #fff;
    border: 1px solid silver;
    padding: 5px 0;
    position: relative;
    z-index: 10000000000;
}

.eqjs-menu-searchDiv{
    border-bottom: 1px solid silver;
    background-color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.eqjs-menu-searchBox{
    width: 93%;
    padding: 2px 5px;
    font-weight: normal;
    font-size: 15px;
}

.eqjs-menu-searchBox.eqjs-dialog {
    position: relative;
}

.eqjs-menu-scrollDiv{
    min-width: 140px;
    white-space: nowrap;
    overflow: auto;
    font-size: 14px;
}

.eqjs-menu-scrollDiv:focus {
    outline:none;
}

.eqjs-menu-itemDiv input{
    margin: 4px 10px 0 0;
    vertical-align: top;
}

.eqjs-menu-itemDiv{
    font-size: 1em;
    color: #333333;
    padding: 5px 10px 5px 20px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eqjs-menu-itemDiv.eqjs-menu-selected {
    padding-left: 3px;
}

.eqjs-menu-itemDiv.eqjs-menu-selected:before {
    content:  "\2713 ";
    margin-right: 5px;
}

.eqjs-menu-itemDiv-arrow {
    position: absolute;
    right: 10px;
}


.eqjs-menu-itemDiv-hasChildren{
    padding: 5px 20px 5px 20px;
    font-size: 1em;
}

.eqjs-menu-itemDiv-filter-hasChildren {
    padding: 5px 20px 5px 20px;
    font-size: 1em;
    font-weight: bold;
}

.eqjs-menu-itemDiv-highlight {
    color: black;
    background-color: yellow;
}

.eqjs-menu-itemDiv:hover, .eqjs-menu-itemDiv.active{
    background: #ecf3f5;
    color: #1776b1;
}

.eqjs-menu-itemDiv-text{
    float: left;
}

.eqjs-menu-applyDiv{
    padding: 5px;
    border-bottom: 1px solid silver;
    margin: 0 0 5px 0;
}

.eqjs-menu-applyDiv button{
    padding: 0 5px;
    cursor: pointer;
}

.eqjs-menu-cancel{
    margin: 0 0 0 15px;
}


/* Columns panel */

.eqjs-qc-columns{
    overflow-y: auto;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.eqjs-qc-column-drag{
    opacity: 0.7;
    cursor: n-resize !important;
    background: #F1F1F1;
    width: 664px;
    position:relative;
}

.eqjs-qc-column-drag-forbidden{
    background: #F7819F;
}

.eqjs-qc-column-sort{
    opacity: 0.7;
    cursor: n-resize !important;
    background: #E8F3FF;
    padding: 0 20px !important;
}

.eqjs-fb-container {
    position: relative;
}

.eqjs-fb-header {
    height: 30px;
    line-height: 30px;
    background-color: grey;
    color: white;
    font-weight: bold;
}

.eqjs-fb-header-link:hover {
    cursor: pointer;
    text-decoration: underline;
}


.eqjs-fb-querypanelblock {
    border: 1px solid #aaa;
}

.eqjs-fb-querypanel {
    background: none repeat scroll 0 0 #f9f9f9;
    border-bottom: 1px dotted #AAA;
}

.eqjs-fb-querypanel > .eqjs-qp-group-root > .eqjs-qp-conditions {
    max-height: 200px !important;
}

.eqjs-fb-querypanel .eqjs-qp-addrow {
    margin-bottom: 8px;
}

.eqjs-fb-header-icon {
    width: 30px;
    height: 100%;
    display: inline-block;
    background-color: #099cff !important;
}

.eqjs-fb-header-icon span {
    padding-left: 8px;
    padding-top: 8px;
}

.eqjs-fb-header-arrowblock {
    width: 26px;
    height: 100%;
    display: inline-block;
    padding-top: 11px;
    padding-left: 6px;
}

.eqjs-fb-header-arrow {
	border-color: white transparent;
	border-style: solid;
	border-width: 7px 7px 0px 7px;
	height: 0px;
	width: 0px;
}

.eqjs-fb-header-arrowUp {
	border-width: 0px 7px 7px 7px !important;
}

.eqjs-fb-header-textblock {
    display: inline-block;
    padding-left: 6px;
    padding-right: 2px;
}

.eqjs-fb-header-textblock a {
    color: white;
    font-weight: bold;
}

.eqjs-fb-header-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.eqjs-fb-buttonsblock {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 4px;
}

.eqjs-fb-button {
    -moz-user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 2px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    color: #fff;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset, 0 1px 1px rgba(0, 0, 0, 0.075);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
    margin-right:10px;
}

.eqjs-fb-button:hover, .eqjs-fb-button:active, .eqjs-fb-button:focus {
    text-decoration: none;
    outline: 0 none;
    color: #fff;
}

.eqjs-fb-button-apply {
    background-image: linear-gradient(to bottom, #5cb85c 0px, #419641 100%);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
}

.eqjs-fb-button-clear {
    background-image: linear-gradient(to bottom, #428bca 0px, #2d6ca2 100%);
    background-repeat: repeat-x;
    border-color: #2b669a;
}

.eqjs-fb-button-apply:hover, .eqjs-fb-button-apply:active, .eqjs-fb-button-apply:focus {
    background-color: #419641;
    border-color: #3e8f3e;
}

.eqjs-fb-button-clear:hover, .eqjs-fb-button-clear:active, .eqjs-fb-button-clear:focus {
    background-color: #2d6ca2;
    border-color: #2b669a;
}

/*  Progress indicator  */
.eqjs-progress-win8 {
    width: 40px;
    height: 40px;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
}

.eqjs-progress-win8 .wBall {
    position: absolute;
    width: 38px;
    height: 38px;
    opacity: 0;
    -moz-transform: rotate(225deg);
    -moz-animation: orbit 7.15s infinite;
    -webkit-transform: rotate(225deg);
    -webkit-animation: orbit 7.15s infinite;
    -ms-transform: rotate(225deg);
    -ms-animation: orbit 7.15s infinite;
    -o-transform: rotate(225deg);
    -o-animation: orbit 7.15s infinite;
    transform: rotate(225deg);
    animation: orbit 7.15s infinite;
}

.eqjs-progress-win8 .wBall .wInnerBall {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #000000;
    left: 0px;
    top: 0px;
    border-radius: 5px;
}

.eqjs-progress-win8 #wBall_1 {
-webkit-animation-delay: 1.56s;
animation-delay: 1.56s;
}

.eqjs-progress-win8 #wBall_2 {
-webkit-animation-delay: 0.31s;
animation-delay: 0.31s;
}

.eqjs-progress-win8 #wBall_3 {
-webkit-animation-delay: 0.62s;
animation-delay: 0.62s;
}

.eqjs-progress-win8 #wBall_4 {
-webkit-animation-delay: 0.94s;
animation-delay: 0.94s;
}

.eqjs-progress-win8 #wBall_5 {
-webkit-animation-delay: 1.25s;
animation-delay: 1.25s;
}

@-webkit-keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        -webkit-transform: rotate(180deg);
        -webkit-animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        -webkit-transform: rotate(300deg);
        -webkit-animation-timing-function: linear;
        -webkit-origin: 0%;
    }

    30% {
        opacity: 1;
        -webkit-transform: rotate(410deg);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-origin: 7%;
    }

    39% {
        opacity: 1;
        -webkit-transform: rotate(645deg);
        -webkit-animation-timing-function: linear;
        -webkit-origin: 30%;
    }

    70% {
        opacity: 1;
        -webkit-transform: rotate(770deg);
        -webkit-animation-timing-function: ease-out;
        -webkit-origin: 39%;
    }

    75% {
        opacity: 1;
        -webkit-transform: rotate(900deg);
        -webkit-animation-timing-function: ease-out;
        -webkit-origin: 70%;
    }

    76% {
        opacity: 0;
        -webkit-transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: rotate(900deg);
    }
}

@keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        transform: rotate(180deg);
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        transform: rotate(300deg);
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        origin: 0%;
    }

    30% {
        opacity: 1;
        transform: rotate(410deg);
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        origin: 7%;
    }

    39% {
        opacity: 1;
        transform: rotate(645deg);
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        origin: 30%;
    }

    70% {
        opacity: 1;
        transform: rotate(770deg);
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        origin: 39%;
    }

    75% {
        opacity: 1;
        transform: rotate(900deg);
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        origin: 70%;
    }

    76% {
        opacity: 0;
        transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        transform: rotate(900deg);
    }
}
/*  End of Progress indicator  */



/* ColumnsBar START */
.eqjs-cb-panel, .eqjs-sb-panel {
    display: flex;
    background: #f9f9f9;
    min-height: 40px;
    position: relative;
    font: normal 14px Trebuchet MS, Tahoma, Verdana, Geneva, Arial, Helvetica, sans-serif;
}

.eqjs-cb-header, .eqjs-sb-header {
    display: none;
}

.eqjs-cb-columns, .eqjs-sb-columns {
    position: relative;
    white-space: normal;
    overflow-y: unset;
    min-height: 40px;
    padding: 0;
    max-height: 1000px !important;
}

.eqjs-gp-addrow,
.eqjs-cb-addrow,
.eqjs-sb-addrow {
    margin: 5px 2px 2px 2px;
    position: relative;
    flex: 0 0 auto;
    vertical-align: baseline;
    height: 23px;
    width: 23px;
    padding:0;
}

.eqjs-gp-addrow a,
.eqjs-cb-addrow a,
.eqjs-sb-addrow a {
  background: scroll 0 0 rgba(0, 0, 0, 0);
  border-bottom: none;
  width: 100%;
  height: 100%;
  margin:0;

  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.eqjs-gp-addrow > a:hover,
.eqjs-cb-addrow > a:hover,
.eqjs-sb-addrow > a:hover{
    border-bottom: none;
}

.eqjs-cb-row, .eqjs-sb-row {
    position: relative;
    height: 27px;
    cursor: pointer;
    display: inline-block;
    margin: 2px;
    background-color: white;
    border: 1px solid #0099CC;
    min-width: 80px;
    font: normal 14px Trebuchet MS, Tahoma, Verdana, Geneva, Arial, Helvetica, sans-serif;
    color: #0099CC;
}

.eqjs-cb-row.eqjs-cb-disabled {
    background-color: lightgray;
    border: 1px dotted #999;
    color: #999;
}

.eqjs-cb-row.eqjs-cb-readonly {
    background-color: white;
    border: 1px dotted #999;
    color: #999;
}

.eqjs-cb-row  .eqjs-cb-buttonsBlock {
    display: none;
}

.eqjs-cb-row  .eqjs-column-sortbutton {
    display: none;
}

.eqjs-cb-row[data-show-buttons]  .eqjs-cb-buttonsBlock {
    display: flex;
}

.eqjs-cb-row[data-show-buttons]  .eqjs-column-sortbutton {
    display: block;
}

.eqjs-cb-expr-block {
    display:none;
}

.eqjs-cb-colelement,
.eqjs-sb-colelement {
    display: inline-block;
    margin: 4px 8px 0 0;
    vertical-align: top;
    font-size: 14px;
    font-weight: normal;
}

.eqjs-cb-colelement:first-child,
.eqjs-sb-colelement:first-child {
    margin-left: 8px !important;
}

.eqjs-cb-colelement > a,
.eqjs-sb-colelement > a {
    border-bottom: none;
    pointer-events: none;
    vertical-align: middle;
}

.eqjs-cb-colelement > a,
.eqjs-sb-colelement > a {
    color: #0099CC;
}

.eqjs-cb-captionelement input {
    margin-top: -4px;
}

.eqjs-cb-captionelement input:focus {
    border: 1px dashed #0099cc;
    box-shadow: none;
    outline: none;    
}

.eqjs-cb-row.active a, .eqjs-cb-row:hover a {
    color: white;
}

.eqjs-cb-row.active, .eqjs-cb-row:hover {
    background-color: #0099CC;
    color: white;
    padding-left: 0;
}

.eqjs-cb-row, .eqjs-cb-row.active, .eqjs-cb-row:hover {
    border-radius: 0.25em;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.eqjs-cb-row.eqjs-cb-disabled.active, .eqjs-cb-row.eqjs-cb-disabled:hover {
    background-color: lightgray;
    color: #666;
    border-color: #666;
}

.eqjs-cb-row.eqjs-cb-readonly.active, .eqjs-cb-row.eqjs-cb-readonly:hover {
    background-color: white;
    color: #666;
    border-color: #666;
}

.eqjs-cb-row .eqjs-button-placeholder {
    margin: 2px 0 2px 1px;
}

.eqjs-cb-row.active .eqjs-cb-button-placeholder, .eqjs-cb-row:hover .eqjs-cb-button-placeholder {
    display: inline-block;
}

.eqjs-cb-row .eqjs-cb-sortimage-placeholder,
.eqjs-sb-row .eqjs-sb-sortimage-placeholder {
	margin: 2px 0;
	height: 100%;
}

.eqjs-cb-img-sorting,
.eqjs-sb-img-sorting {
    width: 24px;
    height: 24px;
}

.eqjs-cb-buttonsBlock {
    position: absolute;
    right: 0;
    height: 27px;
    top: -27px;
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.eqjs-cb-buttonsBlock div {
    width: 22px;
    height: 22px;
    margin: 2px 0 2px 2px;
}

.eqjs-sortable-helper .eqjs-cb-buttonsBlock {
    display: none !important;
}

.eqjs-cb-columns,
.eqjs-sb-columns {
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.eqjs-cb-columns .eqjs-highlight,
.eqjs-sb-columns .eqjs-highlight {
    height: 27px;
    top: 2px;
    width: 100px;
    position: relative;
    background: #ffc107 !important;
}

/* ColumnsBar END */


/* QueryName Widget */
.eqjs-query-name {
    position: relative;
    margin-top: 0 !important;
}

.eqjs-query-name-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 !important;
    padding: 5px 10px 5px 5px;
    width: 100%;
}

.eqjs-query-name:hover .eqjs-query-name-text {
    box-shadow: none;
}

.eqjs-query-name-asterisk {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 10000;

    color: red;
    font-size: 1rem;
    font-weight: 700;
}

.eqjs-query-name .eqjs-query-name-tooltip {
    visibility: hidden;
}

.eqjs-query-name-theme {
    font: normal 12pt "Segoe UI Light", "Segoe Light", "Segoe UI", Arial, Helvetica, sans-serif;
    font-weight: 600;
}

/* QueryName Widget END */

/* Process Bar start */
.eqjs-process-bar {
    display: 'none';
    height: 4px;
    width: 100%;
    position: fixed;
    top: 0;
	left: 0;
    z-index: 65000;
    overflow: hidden;
    background-color: #ddd;
  }
  .eqjs-process-bar:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #2980b9;
    -webkit-animation: eqjs-process-bar-loading 2s linear infinite;
            animation: eqjs-process-bar-loading 2s linear infinite;
  }
  
  @-webkit-keyframes eqjs-process-bar-loading {
      from {left: -200px; width: 30%;}
      50% {width: 30%;}
      70% {width: 70%;}
      80% { left: 50%;}
      95% {left: 120%;}
      to {left: 100%;}
  }
  
  @keyframes eqjs-process-bar-loading {
      from {left: -200px; width: 30%;}
      50% {width: 30%;}
      70% {width: 70%;}
      80% { left: 50%;}
      95% {left: 120%;}
      to {left: 100%;}
  }

  .eqjs-process-bar.local {
      position: absolute;
      display: 'block';
  }
/* Process Bar end */


a.eqjs-disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
} 


input.eqjs-invalid {
    border:1px solid red !important;
}



.eqjs-maximized-2window {
    position: fixed !important;
    background-color: white;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    transition: 1s;
    padding: 5px;
}

/*
.eqjs-maximized-2container {
    position: absolute !important;
    background-color: white;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: 1s;
}
*/

.eqjs-maximizable {
    position: relative;
}

.eqjs-container-4max {
    position: relative;
}

.eqjs-max-button {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: white;
    right: 4px;
    top: 4px;
    z-index: 10000;
    border: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAr0lEQVQ4jaWTwQ2DMAxFn1EPDMAyVTl1g94Yg7IIWyBVHKrORWfo7yWUyBKKK76UQ+z8/+3EMUkcwSlyyMw64ObCL0lTSMDhCjRABUxICi+gBwQswCwp1kJqowdG4A5cfomg85Cch7Sf1woi5DYnp1gHdFGBGmh38wXnumjgS3I97zp7ge1SNnIfuuBcIHvnENkLLP+SJVFls9IAb+BsZo80/0Wsk/gEPhGChx39zl8oCGZnXNzK6gAAAABJRU5ErkJggg==');
    background-position: 3px 3px;
    background-repeat: no-repeat;
    opacity: 0.6;
}

.eqjs-max-button:hover {
    opacity: 1;
}

.eqjs-maximized > .eqjs-max-button {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAvklEQVQ4jaWTuw3CMBCGvz8lygA0rJIBKKMUDJABIhgH5mAAVknDBJT8FHlgmSg2cNLJOp3v870s2/wjxS9BklpJhyyApEpSH9gdcAZKAGyvKtAM1wzQAQa62Z8LWAr+FmDg+OHPANQBYNJ+8is1RkkbYB81/G77BrwBkipgG1x6Alfbj9UXglT7hVTrVIlFANrZFnAK+OlFixo2j2o8m2STl4JHOx8AtMQbNvSkSgFkm/FjlLYvyZojSe5BSl4yFEh5bRiMkwAAAABJRU5ErkJggg==');
}

.eqjs-query-text {
    font-size: 16px;
}

.eqjs-query-text-attr {
    color: #0099cc;
    font-weight: 500;
}

.eqjs-query-text-op {
    color: #993333;
    font-weight: 400;
}

.eqjs-query-text-expr {
    color: #00881f;
    font-weight: 600;
}

.eqjs-query-text-conj {
    color: #666;
    font-weight: 700;
}

.eqjs-widget-disabled {
    position: relative;
}

.eqjs-widget-disabled:after {
    content: "";
    position: absolute;
    width: 100%;
    height: inherit;
    background-color: rgba(255,255,255,0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}