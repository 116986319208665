/* EasyData forms: buttons */

.kfrm-buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.kfrm-buttons.align-right {
    justify-content: flex-end;
}

.kfrm-buttons.align-center {
    justify-content: center;
}

.kfrm-buttons:last-child {
    margin-bottom: -.5rem;
}

.kfrm-buttons .kfrm-button {
    margin-bottom: .5rem;
}

.kfrm-button {
    position: relative;
    vertical-align: top;
    line-height: 1.5;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1em;
    height: 2.5em;
    -moz-appearance: none;
    -webkit-appearance: none;


    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(.5em - 1px);
    text-align: center;
    white-space: nowrap;

    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

.kfrm-button:not(:last-child) {
    margin-right: .5em;
}

.kfrm-button.is-hovered, .kfrm-button:hover {
    border-color: #b5b5b5;
    color: #363636;
}

.kfrm-button.is-focused, .kfrm-button:focus {
    outline: none;
    border-color: #3273dc;
    color: #363636;
}

.kfrm-button.is-focused:not(:active), .kfrm-button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(50,115,220,.25);
}

.kfrm-button.is-active, .kfrm-button:active {
    border-color: #4a4a4a;
    color: #363636;
}

.kfrm-button[disabled], fieldset[disabled] .kfrm-button {
    background-color: #fff;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: .5;
    cursor: not-allowed;
}

.kfrm-button.is-loading {
    color: transparent!important;
    pointer-events: none;
}

.kfrm-button.is-loading::after {
    -webkit-animation:spinAround .5s infinite linear;
    animation:spinAround .5s infinite linear;
    border:2px solid #dbdbdb;
    border-radius:290486px;
    border-right-color:transparent;
    border-top-color:transparent;
    content:"";
    display:block;
    height:1em;
    width:1em;

    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute!important;
}

@-webkit-keyframes spinAround {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes spinAround {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

/* SIZES */

.kfrm-button.size-small,
.kfrm-buttons.size-small .kfrm-button:not(.size-default):not(.size-medium):not(.size-large) {
    border-radius: 2px;
    font-size: .75rem;
}

.kfrm-button.size-default
.kfrm-buttons.size-default .kfrm-button:not(.size-small):not(.size-medium):not(.size-large) {
    font-size: 1rem;
}

.kfrm-button.size-medium,
.kfrm-buttons.size-medium .kfrm-button:not(.size-small):not(.size-default):not(.size-large) {
    font-size: 1.25rem;
}

.kfrm-button.size-large
.kfrm-buttons.size-large .kfrm-button:not(.size-small):not(.size-default):not(.size-medium) {
    font-size: 1.5rem;
}

/* SIZES END */



/* COLORS */

/* white */
.kfrm-button.is-white {
    background-color: #fff;
    border-color: transparent;
    color: #0a0a0a;
}

.kfrm-button.is-white.is-hovered, .kfrm-button.is-white:hover {
    background-color: #f9f9f9;
    border-color: transparent;
    color: #0a0a0a;
}

.kfrm-button.is-white.is-focused, .kfrm-button.is-white:focus {
    border-color: transparent;
    color: #0a0a0a;
}

.kfrm-button.is-white.is-focused:not(:active), .kfrm-button.is-white:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(150,150,150,.15);
}

.kfrm-button.is-white.is-active, .kfrm-button.is-white:active {
    background-color: #f2f2f2;
    border-color: transparent;
    color: #0a0a0a;
}

/* dark */

.kfrm-button.is-dark {
    background-color: #363636e6;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-dark.is-hovered, .kfrm-button.is-dark:hover {
    background-color: #2f2f2f;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-dark.is-focused, .kfrm-button.is-dark:focus {
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-dark.is-focused:not(:active), .kfrm-button.is-dark:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(54,54,54,.25);
}

.kfrm-button.is-dark.is-active, .kfrm-button.is-dark:active {
    background-color: #292929;
    border-color: transparent;
    color: #fff;
}

/* primary */

.kfrm-button.is-primary {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-primary.is-hovered, .kfrm-button.is-primary:hover {
    background-color: #00c4a7;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-primary.is-focused, .kfrm-button.is-primary:focus {
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-primary.is-focused:not(:active), .kfrm-button.is-primary:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(0,209,178,.25);
}

.kfrm-button.is-primary.is-active, .kfrm-button.is-primary:active {
    background-color: #00b89c;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-primary.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

/* link */

.kfrm-button.is-link {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-link.is-hovered, .kfrm-button.is-link:hover {
    background-color: #276cda;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-link.is-focused, .kfrm-button.is-link:focus {
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-link.is-focused:not(:active), .kfrm-button.is-link:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(50,115,220,.25);
}

.kfrm-button.is-link.is-active, .kfrm-button.is-link:active {
    background-color: #2366d1;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-link.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

/* info */

.kfrm-button.is-info {
    background-color: #3298dc;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-info.is-hovered, .kfrm-button.is-info:hover {
    background-color: #2793da;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-info.is-focused, .kfrm-button.is-info:focus {
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-info.is-focused:not(:active), .kfrm-button.is-info:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(50,152,220,.25);
}

.kfrm-button.is-info.is-active, .kfrm-button.is-info:active {
    background-color: #238cd1;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-info.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

/* success */

.kfrm-button.is-success {
    background-color: #48c774;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-success.is-hovered, .kfrm-button.is-success:hover {
    background-color: #3ec46d;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-success.is-focused:not(:active), .kfrm-button.is-success:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(72,199,116,.25);
}

.kfrm-button.is-success.is-active, .kfrm-button.is-success:active {
    background-color: #3abb67;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-success.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

/* warning */

.kfrm-button.is-warning {
    background-color: #ffdd57;
    border-color: transparent;
    color: rgba(0,0,0,.7);
}

.kfrm-button.is-warning.is-hovered, .kfrm-button.is-warning:hover {
    background-color: #ffdb4a;
    border-color: transparent;
    color: rgba(0,0,0,.7);
}

.kfrm-button.is-warning.is-focused, .kfrm-button.is-warning:focus {
    border-color: transparent;
    color: rgba(0,0,0,.7);
}

.kfrm-button.is-warning.is-focused:not(:active), .kfrm-button.is-warning:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(255,221,87,.25);
}

.kfrm-button.is-warning.is-active, .kfrm-button.is-warning:active {
    background-color: #ffd83d;
    border-color: transparent;
    color: rgba(0,0,0,.7);
}

.kfrm-button.is-warning.is-loading::after {
    border-color: transparent transparent rgba(0,0,0,.7) rgba(0,0,0,.7)!important;
}

/* danger */

.kfrm-button.is-danger {
    background-color: #f14668;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-danger.is-hovered, .kfrm-button.is-danger:hover {
    background-color: #f03a5f;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-danger.is-focused, .kfrm-button.is-danger:focus {
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-danger.is-focused:not(:active), .kfrm-button.is-danger:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(241,70,104,.25);
}

.kfrm-button.is-danger.is-active, .kfrm-button.is-danger:active {
    background-color: #ef2e55;
    border-color: transparent;
    color: #fff;
}

.kfrm-button.is-danger.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

/* COLORS END */


/* BUTTONS END *//* EasyData forms: columns */

.kfrm-row {
    margin-left: -.75rem;
    margin-right: -.75rem;
    margin-top: -.75rem;
}

.kfrm-row:not(:last-child) {
    margin-bottom: calc(1.5rem - .75rem);
}

@media screen and (min-width: 769px), print {
    .kfrm-row {
        display: flex;
        flex-wrap: wrap;
    }

    .kdlg-modal-window.size-small .kfrm-row {
        display: block;
    }


    /* 12 columns grid */
    .kfrm-column.size-1 {
        flex: none;
        width: 8.33333%;
    }

    .kfrm-column.size-2 {
        flex: none;
        width: 16.66667%;
    }

    .kfrm-column.size-3 {
        flex: none;
        width: 25%;
    }

    .kfrm-column.size-4 {
        flex: none;
        width: 33.33333%;
    }

    .kfrm-column.size-5 {
        flex: none;
        width: 41.66667%;
    }

    .kfrm-column.size-6 {
        flex: none;
        width: 50%;
    }

    .kfrm-column.size-7 {
        flex: none;
        width: 58.33333%;
    }

    .kfrm-column.size-8 {
        flex: none;
        width: 66.66667%;
    }

    .kfrm-column.size-9 {
        flex: none;
        width: 75%;
    }

    .kfrm-column.size-10 {
        flex: none;
        width: 83.33333%;
    }

    .kfrm-column.size-11 {
        flex: none;
        width: 91.66667%;
    }

    .kfrm-column.size-12 {
        flex: none;
        width: 100%;
    }



}

.kfrm-column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: .75rem;
}

/* COLUMNS END */

/* EasyData forms: controls */

.kfrm-form .errors-block {
    color: red;
}


.kfrm-form .control {
    box-sizing: border-box;
    clear: both;
    position: relative;
    text-align: inherit;
    display: inline-flex;
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
}

.kfrm-form .icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5em;
    width: 1.5em;
}

.kfrm-form .control.has-icons-left input, .kfrm-form .control.has-icons-left .select select {
    padding-left: 2.5em;
}

.kfrm-form .control.has-icons-right input, .kfrm-form .control.has-icons-right .select select {
    padding-right: 2.5em;
}

.kfrm-form .control.has-icons-left .icon.is-left {
    left: 0;
}

.kfrm-form .control.has-icons-right .icon.is-right {
    right: 0;
}

.kfrm-form .control .icon {
    color: #dbdbdb;
    height: 2.5em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 2.5em;
    z-index: 4;
}

.kfrm-form .control .icon.is-clickable {
    pointer-events: auto;
}

/*
.kfrm-form .control input:focus~.icon, .kfrm-form .control .select:focus~.icon {
    color: #4a4a4a;
}
*/

.kfrm-form .control .icon.is-clickable:hover {
    color: #4a4a4a;
    cursor: pointer;
}


.kfrm-form input.is-valid:not([type='checkbox']),
.kfrm-form input.is-valid:not([type='checkbox']):hover {
    border-color: green;
}

.kfrm-form input.is-invalid:not([type='checkbox']),
.kfrm-form input.is-invalid:not([type='checkbox']):hover {
    border-color: red;
}

.kfrm-form input:not([type='checkbox']):-moz-read-only {
    background-color: #e9ecef;
    opacity: 1;
}

.kfrm-form input:not([type='checkbox']):read-only {
    background-color: #e9ecef;
    opacity: 1;
}

.kfrm-form input:not([type='checkbox']), .kfrm-select select, .kfrm-form textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1em;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
    position: relative;
    vertical-align: top;
}

.kfrm-form input:not([type='checkbox']), .kfrm-select select, .kfrm-form textarea {
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    outline: 0;
}

.kfrm-form input:not([type='checkbox']), .kfrm-form textarea {
    box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
    max-width: 100%;
    width: 100%;
}

.kfrm-form input:not([type='checkbox']):hover, .kfrm-form input:not([type='checkbox']).is-hovered, .kfrm-form textarea:hover, .kfrm-form textarea.is-hovered, .kfrm-select select:hover, .kfrm-select select.is-hovered {
    border-color: #b5b5b5;
}

.kfrm-form input:not([type='checkbox']):active, .kfrm-form input:not([type='checkbox']).is-active, .kfrm-form textarea:active, .kfrm-form textarea.is-active, .kfrm-select select:active, .kfrm-select select.is-active, 
.kfrm-form input:not([type='checkbox']):focus, .kfrm-form input:not([type='checkbox']).is-focused, .kfrm-form textarea:focus, .kfrm-form textarea.is-focused, .kfrm-select select:focus, .kfrm-select select.is-focused {
    border-color: #3273dc;
    box-shadow: 0 0 0 0.125em rgba(50,115,220,.25);
    outline: 0;
}


.kfrm-select {
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: top;
    padding: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.kfrm-select.full-width {
    width: 100%;
}

.kfrm-select select::-ms-expand {
    display: none !important;
}

.kfrm-select:not(.is-multiple) {
    height: 2.5em;
}

.kfrm-select select {
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: 0;
}

.kfrm-select select:not([multiple]) {
    padding-right: 2.5em;
}

.kfrm-select.kfrm-select.full-width select:not([multiple]) {
    width: 100%;
}


 .kfrm-select:not(.is-multiple):not(.is-loading)::after {
    border: 3px solid #3273dc;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: .425em;
    margin-top: -.5em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: .425em;
    right: .7em;
    z-index: 4;
}


.kfrm-form textarea {
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: calc(.75em - 1px);
    resize: vertical;
}

.kfrm-form textarea:not([rows]) {
    max-height: 40em;
    min-height: 8em;
}

/* CONTROLS END */

/* EasyData forms: layout */
.kfrm-form {
    font-size: 16px;
}

.kfrm-form fieldset {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 20px;
}

.kfrm-form fieldset > legend {
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 10px;
            padding-inline-end: 10px;
    font-weight: 600;
    width: auto;
    font-size: 16px;
    margin-bottom: 0;
}

.kfrm-break, .kfrm-break-50 {
    margin-top: 50px;
}

.kfrm-break-10 {
    margin-top: 10px;
}

.kfrm-break-20 {
    margin-top: 20px;
}

.kfrm-break-30 {
    margin-top: 30px;
}

.kfrm-break-40 {
    margin-top: 40px;
}

.kfrm-break-60 {
    margin-top: 60px;
}

.kfrm-break-70 {
    margin-top: 70px;
}

.kfrm-break-80 {
    margin-top: 10px;
}


.kfrm-fields, .kfrm-fields.col-a {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    grid-auto-rows: auto;
}

.kfrm-fields:not(:last-child) {
    margin-bottom: calc(1.5rem - .75rem);
}

.kfrm-fields > label {
    padding: .5em 0;
}

.kfrm-fields .label-compact {
    font-size: 0.9em;
}

fieldset .kfrm-fields > label {
    padding: 0;
    margin: 0;
}

.kfrm-fields > label:not(.checkbox) {
    font-weight: 600;
}

.kfrm-fields.label-above > label:not(.checkbox) {
    padding: 0.5em 0 0 0;
    margin-bottom: -0.5em;
    font-weight: 500;
}

.kfrm-fields.label-align-right > label:not(.checkbox) {
    text-align: right;
}

.kfrm-fields.col-a-1 {
    grid-template-columns: auto 1fr;
}

.kfrm-fields.col-1-a {
    grid-template-columns: 1fr auto;
}

.kfrm-fields.col-a-a {
    grid-template-columns: auto auto;
}

.kfrm-fields.col-1-1 {
    grid-template-columns: 1fr 1fr;
}

.kfrm-fields.col-1-2 {
    grid-template-columns: 1fr 2fr;
}

.kfrm-fields.col-1-3 {
    grid-template-columns: 1fr 3fr;
}

.kfrm-fields.col-2-1 {
    grid-template-columns: 2fr 1fr;
}

.kfrm-fields.col-3-1 {
    grid-template-columns: 3fr 1fr;
}

.kfrm-fields.col-2-3 {
    grid-template-columns: 2fr 3fr;
}

.kfrm-fields.col-3-2 {
    grid-template-columns: 3fr 2fr;
}

.kfrm-fields.is-horizontal {
    display: flex;
    padding: 0;
}

.kfrm-fields.is-horizontal.align-right {
    justify-content: flex-end;
}

.kfrm-fields.is-horizontal.align-center {
    justify-content: center;
}

.kfrm-fields.is-horizontal.align-evenly {
    justify-content: space-evenly;
}

.kfrm-fields.is-horizontal > label:not(:first-child) {
    margin-left: .5rem;
}

/* IE */
.kfrm-fields-ie {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

.kfrm-field-ie {
    display: flex;
    margin-bottom: 1em;
}

.kfrm-field-ie > *:nth-child(1) {
    margin-right: 20px;
}

.kfrm-field-ie > label {
    padding: .5em 0;
}

.kfrm-fields-ie.col-ie-1-1 > .kfrm-field-ie > *:nth-child(1) {
  flex: 0 0 50%;
}

.kfrm-fields-ie.col-ie-1-2 > .kfrm-field-ie > *:nth-child(1) {
  flex: 0 0 33.3333%;
}

.kfrm-fields-ie.col-ie-1-3 > .kfrm-field-ie > *:nth-child(1) {
  flex: 0 0 25%;
}

.kfrm-fields-ie.col-ie-1-4 > .kfrm-field-ie > *:nth-child(1) {
  flex: 0 0 20%;
}

.kfrm-fields-ie.label-align-right .kfrm-field-ie > *:nth-child(1) {
  text-align: right;
}

.kfrm-fields-ie.label-above .kfrm-field-ie > *:nth-child(1):not(.checkbox) {
  padding: 0.5em 0 0 0;
  margin-bottom: -0.5em;
}

.kfrm-field-ie > label:not(.checkbox), .kfrm-fields-ie > label:not(.checkbox) {
  font-weight: 600;
}

.kfrm-fields-ie.is-horizontal {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
}

.kfrm-fields-ie.kfrm-fields-ie.is-horizontal > *:not(.kfrm-select) {
  padding: .5em;
}

.kfrm-fields-ie.is-horizontal > *:not(:first-child) {
  margin-left: 1em;
}

.kfrm-fields-ie > label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.kfrm-field > label {
  padding-right: 0.5em;
}

.kfrm-callout {
  font-size: 1.2em;
  text-align: center;
}
/* FORMS END */
/* EasyData forms: DateTime picker */
.kdtp {
    border: 1px solid silver;
    background-color: white;
    box-shadow: 4px 8px 15px 0px rgba(0,0,0,.16);
    border-radius: 8px;
    overflow: hidden;    
    z-index: 100000;
    outline: none;
    font-size: 0.7rem;
}

.kdtp-buttons {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.kdtp-button {
    height: 2.5em;
    flex: 1;
    background-color: #0099CC;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;    
    font-weight: 500;
}

.kdtp-button:focus, .kdtp-button:active {
    outline: 0;
}

.kdtp-button-now {
    border: none;
    background-color: white;
    color: #0099CC;
}

.kdtp-button:nth-child(2) {
    border-top-left-radius: 8px;
}

.kdtp-button:not(:first-child) {
    margin-left: 2px;
}

.kdtp-cal {
    padding: 0;
}

.kdtp-cal * {
    outline: none !important;
}

.kdtp-cal-body {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: minmax(1em, 1fr) 5px minmax(1em, 1fr) 0.5em minmax(1em, 1fr) 0.5em minmax(1em, 1fr) 0.5em minmax(1em, 1fr) 0.5em minmax(1em, 1fr) 0.5em minmax(1em, 1fr);
    grid-template-columns: repeat(7, minmax(1em, 1fr));
    grid-gap: 0.5em;
	box-sizing: border-box;
    padding: 0 12px;
    -ms-grid-rows: 1fr 0.5em 1fr 0.5em 1fr 0.5em 1fr 0.5em 1fr 0.5em 1fr 0.5em 1fr;
}

.kdtp-cal-body > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(6) {
    -ms-grid-row: 1;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(7) {
    -ms-grid-row: 1;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(9) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(10) {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(11) {
    -ms-grid-row: 3;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(12) {
    -ms-grid-row: 3;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(13) {
    -ms-grid-row: 3;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(14) {
    -ms-grid-row: 3;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(15) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(16) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(17) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(18) {
    -ms-grid-row: 5;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(19) {
    -ms-grid-row: 5;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(20) {
    -ms-grid-row: 5;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(21) {
    -ms-grid-row: 5;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(22) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(23) {
    -ms-grid-row: 7;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(24) {
    -ms-grid-row: 7;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(25) {
    -ms-grid-row: 7;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(26) {
    -ms-grid-row: 7;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(27) {
    -ms-grid-row: 7;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(28) {
    -ms-grid-row: 7;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(29) {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(30) {
    -ms-grid-row: 9;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(31) {
    -ms-grid-row: 9;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(32) {
    -ms-grid-row: 9;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(33) {
    -ms-grid-row: 9;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(34) {
    -ms-grid-row: 9;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(35) {
    -ms-grid-row: 9;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(36) {
    -ms-grid-row: 11;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(37) {
    -ms-grid-row: 11;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(38) {
    -ms-grid-row: 11;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(39) {
    -ms-grid-row: 11;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(40) {
    -ms-grid-row: 11;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(41) {
    -ms-grid-row: 11;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(42) {
    -ms-grid-row: 11;
    -ms-grid-column: 13;
}
.kdtp-cal-body > *:nth-child(43) {
    -ms-grid-row: 13;
    -ms-grid-column: 1;
}
.kdtp-cal-body > *:nth-child(44) {
    -ms-grid-row: 13;
    -ms-grid-column: 3;
}
.kdtp-cal-body > *:nth-child(45) {
    -ms-grid-row: 13;
    -ms-grid-column: 5;
}
.kdtp-cal-body > *:nth-child(46) {
    -ms-grid-row: 13;
    -ms-grid-column: 7;
}
.kdtp-cal-body > *:nth-child(47) {
    -ms-grid-row: 13;
    -ms-grid-column: 9;
}
.kdtp-cal-body > *:nth-child(48) {
    -ms-grid-row: 13;
    -ms-grid-column: 11;
}
.kdtp-cal-body > *:nth-child(49) {
    -ms-grid-row: 13;
    -ms-grid-column: 13;
}

.kdtp-cal-header-input {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;

    font-size: 1em;
    padding: 2px 5px;
    border: 1px solid silver;
    outline: none;
    background: #fff;
    color: #262626;
}

.kdtp-cal-header-input.error {
    border-color: red;
}

.kdtp-cal-header {
    background-color: #0099CC;
    color: white;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    height: 2em;
    line-height: 2em;
}

.kdtp-cal-nav {
    display: flex;
    padding: 0;
    margin: 0.7em 0;
    line-height: 1em;
}

.kdtp-cal-nav-prev, .kdtp-cal-nav-next {
    flex: 0 0 auto;
    font-size: 3em;
    font-weight: 600;
    width: 1em;
    color: #777777;
    text-align: center;    
}

.kdtp-cal-nav-prev:hover, .kdtp-cal-nav-next:hover {
    cursor: pointer;
    color: #0099CC;
}

.kdtp-cal-nav-selectors {
    flex: 1 1 auto;
    display: flex;
    margin: 0 0.5em;
    font-size: 1.2em;
}

.kdtp-cal-nav-month {
    flex: 1 1 auto;
    margin-right: 0.5em;
}

.kdtp-cal-nav-year {
    flex: 0 0 auto;
}

.kdtp-cal-nav-selectors select {
    border: none;
    color: #777777;
    font-weight: 600;
}

.kdtp-cal-nav-selectors select:hover {
    color: #0099CC;
}

.kdtp-cal-nav-selectors select:hover option {
    color: #777777;
}

.kdtp-cal-weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8em;
    font-weight: 600;
	font-size: 0.8em;
    color: #262626;
}

.kdtp-cal-weekday.kdtp-cal-weekend {
    color: #ff685d;
}

.kdtp-cal-day, .kdtp-cal-day-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5em;
    color: #262626;
    border-radius: 4px;
}

.kdtp-cal-day.kdtp-cal-weekend {
    color: #ff685d;
    font-weight: 700;
}

.kdtp-cal-day:hover, .kdtp-cal-day-selected {
    border: 2px solid #0099CC;
    font-weight: 700;
    cursor: pointer;
}

.kdtp-cal-day-current {
    background-color: lightgray;
}

.kdtp-tp {
    display: flex;
    display:-ms-flexbox;
    align-items: center;
    -webkit-align-items:center;
    -ms-flex-align:center;
    margin-top: 1em;
    padding: 0 1em;
}

.kdtp-tp-time {
    flex: 0 0 5em;
    -webkit-flex: 0 0 5em;
    -ms-flex: 0 0 5em;
    text-align: center;
    font-weight: 600;
    color: #777777;
    border: 2px solid #0099CC;
    border-radius: 6px;
    margin-right: 0.5em;
}

.kdtp-tp-sliders {
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
}

.kdtp-tp-time-row {
    display:flex;
    align-items:center;
    height: 1.5em;
    background:linear-gradient(to right,#c5c5c5,#c5c5c5) left 50%/100% 1px no-repeat;
}

.kdtp-tp-time-row input[type=range] {
    background:0 0;
    cursor:pointer;
    flex:1;
    height:100%;
    padding:0;
    margin:0;
    -webkit-appearance:none
}

.kdtp-tp-time-row input[type=range]:hover::-webkit-slider-thumb {
    border-color:#777777
}

.kdtp-tp-time-row input[type=range]:hover::-moz-range-thumb {
    border-color:#777777
}

.kdtp-tp-time-row input[type=range]:hover::-ms-thumb {
    border-color:#777777
}

.kdtp-tp-time-row input[type=range]:focus {
    outline:0
}

.kdtp-tp-time-row input[type=range]:focus::-webkit-slider-thumb {
    background:#0099CC;
    border-color:#0099CC
}

.kdtp-tp-time-row input[type=range]:focus::-moz-range-thumb {
    background:#0099CC;
    border-color:#0099CC
}

.kdtp-tp-time-row input[type=range]:focus::-ms-thumb {
    background:#0099CC;
    border-color:#0099CC
}

.kdtp-tp-time-row input[type=range]::-webkit-slider-thumb {
    -webkit-appearance:none;
    box-sizing:border-box;
    height:12px;
    width:12px;
    border-radius:3px;
    border:1px solid #c5c5c5;
    background:#fff;
    cursor:pointer;
    -webkit-transition:background .2s;
    transition:background .2s;
    margin-top:-6px
}

.kdtp-tp-time-row input[type=range]::-moz-range-thumb {
    box-sizing:border-box;
    height:12px;
    width:12px;
    border-radius:3px;
    border:1px solid #c5c5c5;
    background:#fff;
    cursor:pointer;
    -moz-transition:background .2s;
    transition:background .2s
}

.kdtp-tp-time-row input[type=range]::-ms-thumb {
    box-sizing:border-box;
    height:12px;
    width:12px;
    border-radius:3px;
    border:1px solid #c5c5c5;
    background:#fff;
    cursor:pointer;
    -ms-transition:background .2s;
    transition:background .2s
}

.kdtp-tp-time-row input[type=range]::-webkit-slider-runnable-track {
    border:none;
    height:1px;
    cursor:pointer;
    color:transparent;
    background:0 0
}

.kdtp-tp-time-row input[type=range]::-moz-range-track {
    border:none;
    height:1px;
    cursor:pointer;
    color:transparent;
    background:0 0
}

.kdtp-tp-time-row input[type=range]::-ms-track {
    border:none;
    height:1px;
    cursor:pointer;
    color:transparent;
    background:0 0
}

.kdtp-tp-time-row input[type=range]::-ms-fill-lower {
    background:0 0
}

.kdtp-tp-time-row input[type=range]::-ms-fill-upper {
    background:0 0
}
